import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import Icons from 'shared/ui/icons';
import Access from 'features/access';
import operatorModel from 'entities/operator';
import Preloader from 'shared/ui/preloader';

import Logo from './assets/logo';
import css from './index.module.scss';

const Sidebar = () => {
  const operatorQ = operatorModel.queries.useCurrent();
  const operator = operatorQ.data;

  const logout = operatorModel.mutations.useLogout();

  if (operatorQ.isLoading) {
    return <Preloader />;
  }

  if (!operator) return null;

  const avatarLetters = `${operator.firstname?.charAt(0) ?? ''}${
    operator.lastname?.charAt(0) ?? ''
  }`;

  return (
    <div className={css.sidebar}>
      <div className={cn(css.sidebarLogo, 'flex mb4')}>
        <Logo />
      </div>
      <div className={css.sidebarMenu}>
        <div>
          <NavLink to="/clients">Проекты</NavLink>
        </div>
        <Access roles={['admin', 'content']}>
          <div>
            <NavLink to="/groups">Группы проектов</NavLink>
          </div>
        </Access>
        <div>
          <NavLink to="/workplaces">Рабочие точки</NavLink>
        </div>
        <div>
          <NavLink to="/contractors">Контрагенты</NavLink>
        </div>
        <Access roles={['admin', 'content']}>
          <div>
            <NavLink to="/templates">Шаблоны</NavLink>
          </div>
        </Access>
        <div>
          <NavLink to="/orders">Заказы</NavLink>
        </div>
        <div>
          <NavLink to="/coordination">Координация</NavLink>
        </div>
        <Access roles={['admin']}>
          <div>
            <NavLink to="/heatmap">Тепловая карта</NavLink>
          </div>
        </Access>
        <div>
          <NavLink to="/jobs">Смены</NavLink>
        </div>
        <div>
          <NavLink to="/workers">Гигеры</NavLink>
        </div>
        <div>
          <NavLink to="/interviews">Собеседования</NavLink>
        </div>
        <div>
          <NavLink to="/outsourcing">Подбор</NavLink>
        </div>
        <div>
          <NavLink to="/external-applicants">Внешние отклики</NavLink>
        </div>
        <div>
          <NavLink to="/payments">Платежи</NavLink>
        </div>
        <div>
          <NavLink to="/transactions">Транзакции</NavLink>
        </div>
        <div>
          <NavLink to="/referrals">Акции</NavLink>
        </div>
        <div>
          <NavLink to="/documents">Документы</NavLink>
        </div>
        <div>
          <NavLink to="/support">Поддержка</NavLink>
        </div>
        <div>
          <NavLink to="/reports">Выгрузка отчётов</NavLink>
        </div>
        <div>
          <NavLink to="/regular-reports">Регулярные отчёты</NavLink>
        </div>
        <div>
          <NavLink to="/csvimport">Импорт CSV</NavLink>
        </div>
        <div>
          <NavLink to="/receipts">Чеки</NavLink>
        </div>
        <Access roles={['admin']}>
          <div>
            <NavLink to="/operators">Операторы</NavLink>
          </div>
        </Access>
      </div>

      <div className={css.sidebarFooter}>
        <div className={css.sidebarMenu}>
          <Access roles={['admin', 'financial_manager']}>
            <div>
              <NavLink to="/settings">Настройки</NavLink>
            </div>
          </Access>
        </div>
        <div className={cn(css.sidebarOperator, 'flex flex-center')}>
          <div className={cn(css.sidebarOperatorAvatar, 'mr2')}>
            {avatarLetters}
          </div>
          <div className={css.sidebarOperatorFullname}>
            {operator.firstname ?? ''}
            <br /> {operator.lastname ?? ''}
          </div>
          <div
            className={cn(css.sidebarOperatorExit, 'mla')}
            role="button"
            tabIndex={0}
            onClick={() => logout.mutate()}
          >
            {Icons.exit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
