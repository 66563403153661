import type { Moment } from 'moment';
import moment from 'moment';
import { message } from 'antd';
import type { AxiosError, AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';

import type {
  NestedKeyOf,
  NestedObjectKeyType,
  NoNull,
  NullableOpts,
  ObjectId,
  OptionalKeys,
  SnakeToCamelCase,
} from 'shared/types';
import type { ApiError } from 'shared/api';

export const coordinatesAreEqual = (
  a: [number, number],
  b: [number, number],
): boolean => {
  return a[0] === b[0] && a[1] === b[1];
};

export const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export const formatPrice = (price = 0, showSign = true) =>
  (Math.round(price * 100) / 100).toLocaleString('ru-RU', {
    style: showSign ? 'currency' : undefined,
    currency: 'RUB',
    maximumSignificantDigits: 10,
  });

export const parsePrice = (v?: string) =>
  v ?
    Number(
      v
        .replace(',', '.')
        .replace(/(?<=\..*)\./, '')
        .replaceAll(/[^\d.]/g, '') ?? 0,
    )
  : 0;

export const formatDuration = (minutes: number, short?: boolean) => {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes % 60);
  if (short) return m ? `${h}ч ${m}м` : `${h}ч`;
  return m ? `${h} ч ${m} мин` : `${h} ч`;
};

export const dateToText = (date: Moment | Date | string) => {
  let text;
  const tmp = moment(date);
  const calendar = tmp.calendar().toLowerCase();
  if (calendar.includes('today') || calendar.includes('сегодня')) {
    text = `Сегодня`;
  } else if (calendar.includes('tomorrow') || calendar.includes('завтра')) {
    text = `Завтра`;
  } else {
    text = tmp.format('D MMM').toLowerCase();
  }
  return text?.charAt(0)?.toUpperCase() + text?.slice(1);
};

export function pick<T extends object, K extends keyof T>(
  obj: T,
  keys: readonly K[],
) {
  return keys
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {}) as Pick<
    T,
    (typeof keys)[number]
  >;
}

export const tuple = <T extends unknown[]>(xs: readonly [...T]): T => xs as T;

export const formatPhoneNumber = (phone?: string | number) => {
  if (!phone) return '—';
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const tmp = `${phone}`.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
  if (!tmp || tmp.length < 6) return phone.toString();
  return `+7 (${tmp[2]}) ${tmp[3]}-${tmp[4]}-${tmp[5]}`;
};

export const humanifyId = (_id: string) => _id && `${_id}`.slice(-6);

export const humanifyUrl = (receipt: string) =>
  `${receipt && receipt.slice(0, 50)}...`;

export const initialPagination = () => {
  return {
    current: 1,
    total: 0,
    pageSize: 20,
    position: ['bottomLeft'],
    showSizeChanger: false,
    sort: null,
  };
};

export const formatDate = (
  date?: Moment | Date | string | null,
  format = 'DD.MM.YY, HH:mm',
  fallback = '—',
) => (date ? moment(date).format(format) : fallback);

formatDate.noTime = (date?: Moment | Date | string | null) =>
  formatDate(date, 'DD.MM.YY');

formatDate.fullYear = (date?: Moment | Date | string | null) =>
  formatDate(date, 'DD.MM.YYYY, HH:mm');

formatDate.fullYearNoTime = (date?: Moment | Date | string | null) =>
  formatDate(date, 'DD.MM.YYYY');

export const copyTextToClipboard = (text: string) =>
  new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(resolve, reject);
    } else {
      reject(new Error('Async: Could not copy text'));
    }
  });

export const copyLinkToClipboard = async (link: string) => {
  try {
    await copyTextToClipboard(link);
    void message.success('Ссылка скопирована в буфер обмена');
  } catch (e) {
    void message.error('Не удалось скопировать ссылку на заказ');
  }
};

export const getDiffHours = (
  start: Moment | Date | string,
  end: Moment | Date | string,
  breakTime: string,
) => {
  let hours = 0;

  if (start && end) {
    const momentStart = moment(start);
    const momentEnd = moment(end);

    const separatedBreakTime = breakTime?.split(':');

    if (momentStart.isValid() && momentEnd.isValid()) {
      hours = momentEnd.diff(momentStart, 'hours', true);
    }

    if (separatedBreakTime?.length === 2) {
      const hoursBreakTime =
        Number(separatedBreakTime[0]) + Number(separatedBreakTime[1]) / 60;
      hours -= hoursBreakTime;
    }
  }

  if (hours < 0) {
    hours += 24;
  }

  return hours;
};

export const noop = () => {};

export const getRangeMoments = (
  // eslint-disable-next-line @typescript-eslint/no-shadow
  range: [Moment | Date | string, Moment | Date | string],
) => {
  const [from, to] = range ?? [];
  const dates = [];

  if (from && to) {
    let momentFrom = moment(from);
    let momentTo = moment(to);

    if (momentFrom.isValid() && momentTo.isValid()) {
      if (momentFrom.isAfter(momentTo)) {
        const tmp = momentFrom;
        momentFrom = momentTo;
        momentTo = tmp;
      }

      const diffDays = momentTo.diff(momentFrom, 'days');

      for (let i = 0; i <= diffDays; i++) {
        dates.push(moment(momentFrom));
        momentFrom.add(1, 'day');
      }
    }
  }

  return dates;
};

export const isDateToday = (dateMoment: Moment) => {
  return moment().isSame(dateMoment, 'day');
};

export const isWeekendDay = (dateMoment: Moment) => {
  return ['0', '6'].includes(dateMoment.format('d'));
};

export const randomElement = (arr: unknown[]) => {
  return !arr ? undefined : arr[Math.floor(Math.random() * arr?.length)];
};

export const randomBetween = (from: number, to: number) => {
  return Math.floor(from + Math.random() * (to - from + 1));
};

export interface Pagination {
  total: number;
  pageSize: number;
  current: number;
}

export const getPaginationFromHeaders = (
  headers: AxiosResponse['headers'],
): Pagination => {
  const {
    'x-pagination-per-page': per = '0',
    'x-pagination-current-page': current = '0',
    'x-pagination-total-count': total = '0',
  } = (headers as { [key: string]: string | undefined }) ?? {};

  return {
    total: parseInt(total, 10),
    pageSize: parseInt(per, 10),
    current: parseInt(current, 10),
  };
};

export const thenGetDataWithPagination =
  <T>() =>
  ({ data, headers }: AxiosResponse<T>) => ({
    data,
    pagination: getPaginationFromHeaders(headers),
  });

export const thenGetData = <T>(title?: string) => {
  return ({ data }: AxiosResponse<T>) => {
    if (title) {
      void message.success(title);
    }

    return data;
  };
};

export const getErrorMessage = (
  error: AxiosError<string | { message?: string }> | Error,
  fallback = 'Неизвестная ошибка',
) => {
  if (isAxiosError(error)) {
    const errorData = error.response?.data as AxiosError<
      string | { message?: string }
    >;
    const errorMessage =
      typeof errorData === 'string' ? errorData : errorData?.message;
    return errorMessage ?? fallback;
  }
  return error.message ?? fallback;
};

export const displayError = (
  error: ApiError,
  { title, duration }: { title?: string; duration?: number } = {},
) => {
  const prefix =
    title ??
    'Возникла ошибка при загрузке данных. Пожалуйста, свяжитесь с разработчиками.';

  const errorData = error.response?.data;
  const errorMessage =
    typeof errorData === 'string' ? errorData : errorData?.message;
  const msg = [prefix, errorMessage ?? ''].filter(Boolean).join(': ');

  void message.error(msg, duration ?? 3);

  return msg;
};

export const displayApiError = (
  error: ApiError,
  config?: { title?: string; duration?: number } | string,
  onError?: () => void,
) => {
  if (typeof config === 'string') {
    displayError(error, { title: config, duration: 3 });
    return;
  }
  const { title, duration } = config ?? {};
  displayError(error, {
    title,
    duration,
  });
  onError?.();
};

export const catchError = (
  title?: string,
  onError: (error?: ApiError) => unknown = noop,
) => {
  return (error: ApiError) => {
    if (error.code === 'ERR_CANCELED') throw error;
    const msg = displayError(error, { title });

    // eslint-disable-next-line no-param-reassign
    error.message = [error.message, msg].join('; ');
    onError(error);

    throw error;
  };
};

/** Get number if it is in bounds, otherwise get crossed bound */
export const boundNumber = (
  num: number,
  { min, max }: { min: number; max: number },
) => {
  if (num < min) {
    return min;
  }
  if (num > max) {
    return max;
  }
  return num;
};

/**
 * Проверка на то, что переданное значение является числом (включая 0)
 */
export const isValidNumber = (
  value: unknown,
): value is number | `${number}` => {
  if (value === 0) return true;
  if (value && !Number.isNaN(Number(value))) return true;
  return false;
};

const dateFromTime = (time: string, isNextDay = false) => {
  const fTime = moment(time, 'HH:mm');
  const dateStr = moment().add(isNextDay ? 1 : 0, 'days');

  return dateStr.set({
    hour: fTime.get('hour'),
    minute: fTime.get('minute'),
  });
};

export const getHoursDiff = (startTime: string, finishTime: string) => {
  const isNextDay =
    parseInt(startTime.replace(':', ''), 10) >=
    parseInt(finishTime.replace(':', ''), 10);
  const timeDifference = dateFromTime(finishTime, isNextDay).diff(
    dateFromTime(startTime),
    'milliseconds',
  );
  return Math.round((timeDifference / 3600000) * 100) / 100;
};

export const timeToHours = (breakTime: string) => {
  const hours = +breakTime.split(':')[0];
  const minutesInHours = +(+breakTime.split(':')[1] / 60).toFixed(2);
  return hours + minutesInHours;
};

/**
 * Рассчитывает зарплату из времени
 * @param startTime - начало смены в формате HH:mm
 * @param finishTime - конец смены в формате HH:mm
 * @param breakTime - перерыв в формате HH:mm
 * @param perHour - зарплата в час
 */
export const getSalaryPerJob = (
  startTime: string,
  finishTime: string,
  breakTime: string,
  perHour: number,
) => {
  const [startH, startM] = startTime.split(':').map(Number);
  const [finishH, finishM] = finishTime.split(':').map(Number);
  const [breakH, breakM] = breakTime.split(':').map(Number);

  const hours = finishH - startH - breakH;
  const minutes = finishM - startM - breakM;
  const total = hours + minutes / 60;

  const salaryPerJob = (total > 0 ? total : 24 + total) * perHour;

  return Math.round(salaryPerJob * 100) / 100;
};

/**
 * Возвращает правильное склонение для исчисляемых
 *
 * @example
 * getDeclension(1, ['день', 'дня', 'дней']) // день
 *
 * @param number - число
 * @param titles - массив вариантов
 */
export const getDeclension = (
  num: number,
  endings: [string, string, string],
) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return endings[
    num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
  ];
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getAxiosError = <
  T extends AxiosError<{ message: string }>,
  K extends string | undefined,
>(
  err: T,
  fallback: K,
): string | K => {
  const axiosErr = err;
  const errMessage = axiosErr?.response?.data?.message;
  if (isAxiosError(err) && errMessage) {
    return errMessage;
  }
  return fallback;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanEmptyValues = <T extends Record<string, any>>(values: T) => {
  return Object.entries(values).reduce(
    (acc, [key, val]: [keyof T, T[keyof T]]) => {
      if (val !== null && val !== undefined) {
        acc[key] = val;
      }
      return acc;
    },
    {} as Partial<T>,
  );
};

export const isValidObjectId = (id: string): id is ObjectId =>
  /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(id);

export const toggleInSet = <T>(set: Set<T>, val: T, state?: boolean) => {
  const nextSet = new Set(set);
  if (typeof state === 'boolean') {
    if (state) {
      nextSet.add(val);
    } else {
      nextSet.delete(val);
    }
  } else if (nextSet.has(val)) {
    nextSet.delete(val);
  } else {
    nextSet.add(val);
  }
  return nextSet;
};

export const dictById = <Data extends { _id: ObjectId }[]>(
  data: Data,
): Record<ObjectId, Omit<Data[number], '_id'>> =>
  data.reduce((acc, { _id: id, ...rest }) => ({ ...acc, [id]: rest }), {});

export const omit = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const copy = { ...obj };
  keys.forEach((key) => {
    delete copy[key];
  });
  return copy;
};

export const omitWithLimit = <T extends object, K extends keyof T>(
  obj: T,
  keys: (K | [K, number])[],
): Omit<T, K> => {
  const toOmit = (keys.filter((v) => !(typeof v === 'string')) as [K, number][])
    .filter(([key, limit]) => {
      const val = obj[key];
      return Array.isArray(val) && val.length > limit;
    })
    .map(([key]) => key);
  return omit(obj, toOmit);
};

export const getValByNestedKey = <T extends object, K extends NestedKeyOf<T>>(
  obj: T,
  nestedKey: K,
): NestedObjectKeyType<T, K> => {
  let cursor = obj;
  const path = (nestedKey as string).split('.').map((key) => {
    const num = Number(key);
    if (!Number.isNaN(num)) {
      return num;
    }
    return key;
  });
  path.forEach((key) => {
    cursor = cursor?.[key as keyof T] as unknown as T;
  });
  return cursor as unknown as NestedObjectKeyType<T, K>;
};

export const getNestedKeys = <T extends object>(obj: T): NestedKeyOf<T>[] => {
  const keys: NestedKeyOf<T>[] = [];
  (Object.keys(obj) as (keyof typeof obj)[]).forEach((key) => {
    keys.push(key as NestedKeyOf<T>);
    if (obj[key] && typeof obj[key] === 'object') {
      keys.push(
        ...getNestedKeys(obj[key] as unknown as T).map(
          (newKey) => `${key as string}.${newKey as string}` as NestedKeyOf<T>,
        ),
      );
    }
  });
  return keys;
};

export const fulfillObjDiff = <T extends object>(
  source?: Partial<T>,
  target?: Partial<T>,
): Partial<T> => {
  const result: Partial<T> = { ...target };
  if (!source) return result;
  (Object.keys({ ...source, ...target }) as (keyof T)[]).forEach((key) => {
    if (source[key] !== null && typeof source[key] === 'object') {
      result[key] = fulfillObjDiff(
        source[key] as Partial<T>,
        target?.[key] ?? ({} as Partial<T>),
      ) as T[keyof T];
    } else if (source[key] !== undefined && target?.[key] === undefined) {
      result[key] = undefined;
    }
  });
  return result;
};

export const enumDaysBetweenDates = <T extends string | undefined = undefined>(
  startDate: string | Moment,
  endDate: string | Moment,
  format?: T,
) => {
  const now = moment(startDate, format);
  const end = moment(endDate, format);
  const dates = [];

  while (now.isSameOrBefore(end)) {
    dates.push(format ? now.format(format) : now);
    now.add(1, 'days');
  }
  return dates as T extends string ? string[] : Moment[];
};

export const pickFields = <T>(obj: Record<keyof T, boolean>) =>
  (Object.entries(obj) as [keyof T, boolean][])
    .filter(([, pass]) => pass)
    .map(([field]) => field);

export const removeNulls = <T extends { [key in keyof T]: unknown }>(
  obj: T,
): NoNull<T> => {
  const copy = { ...obj };
  (Object.keys(copy) as (keyof T)[]).forEach((key) => {
    if (copy[key] === null) delete copy[key];
    if (
      copy[key] &&
      !Array.isArray(copy[key]) &&
      typeof copy[key] === 'object'
    ) {
      copy[key] = removeNulls(copy[key] as T) as T[keyof T];
    }
  });
  return copy as NoNull<T>;
};

export const generateRandomKey = () =>
  (Math.random() + 1).toString(36).substring(7);

/** Object.keys but we trust the keys will be keyof Obj */
export const keys = <Obj extends object>(o: Obj) =>
  Object.keys(o) as (keyof Obj)[];

/** Object.entries but we trust the keys will be keyof Obj */
export const entries = <Obj extends object>(o: Obj) =>
  Object.entries(o) as [keyof Obj, Obj[keyof Obj]][];

export const snakeToCamel = <Snake extends string>(snake: Snake) =>
  snake.replace(/(?<=\w)_[a-z]/g, (match) =>
    match.toUpperCase().replace('_', ''),
  ) as SnakeToCamelCase<Snake>;

export const nullifyOpts = <T extends object>(
  obj: T,
  optional: OptionalKeys<T>[],
): NullableOpts<T> => {
  const result = { ...obj } as NullableOpts<T>;
  optional.forEach((key) => {
    if (obj[key] === undefined) {
      result[key as keyof NullableOpts<T>] =
        null as NullableOpts<T>[keyof NullableOpts<T>];
    }
  });
  return result;
};

const sortedReplacer = <T>(_: unknown, value: T) => {
  if (Array.isArray(value)) return value.sort() as unknown as string[];

  if (typeof value === 'object' && value !== null) {
    return keys(value)
      .sort()
      .reduce((sorted, key) => {
        sorted[key] = value[key];
        return sorted;
      }, {} as T);
  }

  return value;
};

export const stringifySorted = (obj: unknown) =>
  JSON.stringify(obj, sortedReplacer);

export const clearEmptyValues = <T extends Record<string, unknown>>(obj: T) => {
  return entries(obj).reduce((acc, [key, val]) => {
    if (Array.isArray(val)) {
      acc[key] = val.filter(Boolean) as unknown as T[keyof T];
      if ((acc[key] as unknown[])?.length === 0) delete acc[key];
    } else if (!val && val !== false) {
      delete acc[key];
    } else {
      acc[key] = val;
    }
    return acc;
  }, {} as T);
};
