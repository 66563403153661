import type { ComponentType, ReactNode } from 'react';
import { memo } from 'react';

import type { OperatorNonSuperRole } from 'entities/operator';
import { acl } from 'entities/operator';

interface AccessProps {
  children: ReactNode | ReactNode[];
  roles: OperatorNonSuperRole[];
  fallback?: ReactNode;
}

const Access = ({ children, roles, fallback = null }: AccessProps) => {
  const hasAccess = acl.useCheck(...roles);
  return <>{hasAccess ? children : fallback}</>;
};

export default memo(Access, (prev, next) => {
  if (
    prev.roles.length !== next.roles.length ||
    prev.roles.some((role) => !next.roles.includes(role))
  ) {
    return false;
  }
  if (prev.children !== next.children) {
    return false;
  }
  if (prev.fallback !== next.fallback) {
    return false;
  }
  return true;
});

export const access =
  (...roles: OperatorNonSuperRole[]) =>
  <Props extends object>(
    Component: ComponentType<Props>,
    fallback: ReactNode = null,
  ) =>
  (props: Props) => (
    <Access roles={roles} fallback={fallback}>
      <Component {...props} />
    </Access>
  );
