import { type ComponentType, type ReactNode, createElement, lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { DOC_GROUPS } from 'shared/constants';
import Login from 'components/Login';
import ResetPassword from 'components/ResetPassword';
import type { OperatorRole } from 'entities/operator';

const Workers = lazy(() => import('pages/workers'));
const WorkerPage = lazy(() => import('pages/worker-page'));
const Jobs = lazy(() => import('pages/jobs'));
const JobPage = lazy(() => import('pages/job-page'));
const Orders = lazy(() => import('pages/orders'));
const Receipts = lazy(() => import('pages/receipts'));
const OrderPage = lazy(() => import('pages/order-page'));
const Payments = lazy(() => import('pages/payments'));
const Documents = lazy(() => import('pages/documents'));
const Contractors = lazy(() => import('pages/contractors'));
const DocumentPage = lazy(() => import('pages/document-page'));
const Support = lazy(() => import('pages/support-list'));
const Clients = lazy(() => import('pages/clients'));
const ClientPage = lazy(() => import('pages/client-page'));
const WorkplaceCreate = lazy(() => import('pages/workplace-create'));
const WorkplacePage = lazy(() => import('pages/workplace-page'));
const Workplaces = lazy(() => import('pages/workplaces'));
const SupportPage = lazy(() => import('pages/support-page'));
const Transactions = lazy(() => import('pages/transactions'));
const TransactionPage = lazy(() => import('pages/transaction-page'));
const TransactionCreate = lazy(() => import('pages/transaction-create'));
const CsvImport = lazy(() => import('pages/csv-import'));
const ExternalApplicants = lazy(() => import('pages/external-applicants'));
const Coordination = lazy(() => import('pages/coordination'));
const Heatmap = lazy(() => import('pages/heatmap'));
const OrderCreate = lazy(() => import('pages/order-create'));
const OutsourcingSelection = lazy(() => import('pages/outsourcing-selection'));
const Interviews = lazy(() => import('pages/interviews'));
const Operators = lazy(() => import('pages/operators'));
const OperatorPage = lazy(() => import('pages/operator-page'));
const OperatorCreate = lazy(() => import('pages/operator-create'));
const Settings = lazy(() => import('pages/settings'));
const OrderEdit = lazy(() => import('pages/order-edit'));
const ContractorCreate = lazy(() => import('pages/contractor-create'));
const ContractorEdit = lazy(() => import('pages/contractor-edit'));
const ClientCreate = lazy(() => import('pages/client-create'));
const Groups = lazy(() => import('pages/groups'));
const Reports = lazy(() => import('pages/reports'));
const RegularReports = lazy(() => import('pages/regular-reports'));
const Referrals = lazy(() => import('pages/referrals'));
const Templates = lazy(() => import('pages/templates'));

type Route = {
  path: string;
  exact?: boolean;
  roles?: OperatorRole[];
} & (
  | {
      component: ComponentType;
    }
  | {
      render: () => ReactNode;
    }
);

const routes: Route[] = [
  {
    path: '/',
    render: () => createElement(Redirect, { to: '/orders' }, null),
    exact: true,
  },
  {
    path: '/workers',
    component: Workers,
    exact: true,
  },
  {
    path: '/workers/:workerId',
    component: WorkerPage,
    exact: true,
  },
  {
    path: '/orders',
    component: Orders,
    exact: true,
  },
  {
    path: '/orders/new',
    component: OrderCreate,
    exact: true,
  },
  {
    path: '/orders/:id',
    component: OrderPage,
    exact: true,
  },
  {
    path: '/orders/:id/edit',
    component: OrderEdit,
    exact: true,
  },
  {
    path: '/outsourcing',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    component: OutsourcingSelection,
    exact: true,
  },
  {
    path: '/external-applicants',
    component: ExternalApplicants,
    exact: true,
  },
  {
    path: '/jobs',
    component: Jobs,
    exact: true,
  },
  {
    path: '/jobs/:id/',
    component: JobPage,
    exact: true,
  },
  {
    path: '/coordination',
    component: Coordination,
    exact: true,
  },
  {
    path: '/heatmap',
    component: Heatmap,
    exact: true,
    roles: ['admin'],
  },
  {
    path: '/payments',
    component: Payments,
    exact: true,
  },
  {
    path: '/documents',
    component: Documents,
    exact: true,
  },
  {
    path: `/documents/:userId/:documentGroup(${Object.keys(DOC_GROUPS).join(
      '|',
    )})`,
    component: DocumentPage,
    exact: true,
  },
  {
    path: '/support',
    component: Support,
    exact: true,
  },
  {
    path: '/support/:id',
    component: SupportPage,
    exact: true,
  },
  {
    path: '/contractors',
    component: Contractors,
    exact: true,
  },
  {
    path: '/contractors/new',
    component: ContractorCreate,
    exact: true,
  },
  {
    path: '/contractors/:id',
    component: ContractorEdit,
    exact: true,
  },
  {
    path: '/clients',
    component: Clients,
    exact: true,
  },
  {
    path: '/clients/new',
    component: ClientCreate,
    exact: true,
  },
  {
    path: '/clients/:clientId',
    component: ClientPage,
    exact: true,
  },
  {
    path: '/groups',
    component: Groups,
    exact: true,
    roles: ['admin', 'content'],
  },
  {
    path: '/workplaces/new',
    component: WorkplaceCreate,
    exact: true,
  },
  {
    path: '/workplaces/:id',
    component: WorkplacePage,
    exact: true,
  },
  {
    path: '/workplaces',
    component: Workplaces,
    exact: true,
  },
  {
    path: '/interviews',
    component: Interviews,
    exact: true,
  },
  {
    path: '/transactions',
    component: Transactions,
    exact: true,
  },
  {
    path: '/transactions/new',
    component: TransactionCreate,
    exact: true,
  },
  {
    path: '/transactions/:id',
    component: TransactionPage,
    exact: true,
  },
  {
    path: '/referrals',
    component: Referrals,
    exact: true,
  },
  {
    path: '/operators',
    component: Operators,
    exact: true,
  },
  {
    path: '/operators/new',
    component: OperatorCreate,
    exact: true,
  },
  {
    path: '/operators/:id',
    component: OperatorPage,
    exact: true,
  },
  {
    path: '/reports',
    component: Reports,
    exact: true,
  },
  {
    path: '/regular-reports',
    component: RegularReports,
    exact: true,
  },
  {
    path: '/csvimport',
    component: CsvImport,
    exact: true,
  },
  {
    path: '/receipts',
    component: Receipts,
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
  },
  {
    path: '/templates',
    component: Templates,
    exact: true,
    roles: ['admin', 'content'],
  },
  {
    path: '*',
    render: () => '404 Страница не найдена',
    exact: true,
  },
];

export const unauthorizedRoutes: Route[] = [
  {
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '*',
    component: Login,
    exact: true,
  },
];

export default routes;
