import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { ApiError } from 'shared/api';
import api from 'shared/api';
import type { ObjectId } from 'shared/types';

import { getToken } from './controller';
import type { Operator, OperatorRole } from './types';

interface OperatorsDictConfig {
  role?: OperatorRole;
  managers?: boolean;
}

const getOperatorsDictionary = (params: OperatorsDictConfig) =>
  api
    .get<Record<ObjectId, string>>('/dictionaries/operators', {
      params,
    })
    .then(({ data }) => data);

type DefData = Record<ObjectId, string>;

type OperatorsQOptions<Data = DefData> = Omit<
  UseQueryOptions<DefData, ApiError, Data>,
  'queryKey' | 'queryFn'
>;

export const useOperatorsCustom = <Data = DefData>(
  config: OperatorsDictConfig = {},
  options?: OperatorsQOptions<Data>,
) =>
  useQuery<DefData, ApiError, Data>({
    queryKey: ['dictionaries', 'operators', config],
    queryFn: () => getOperatorsDictionary(config),
    ...options,
  });

export const useOperators = <Data = DefData>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsCustom({}, options);
export const useCoordinators = <Data = DefData>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsCustom({ role: 'coordinator' }, options);
export const useRecruiters = <Data = DefData>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsCustom({ role: 'recruiter' }, options);
export const useManagers = <Data = DefData>(
  options?: OperatorsQOptions<Data>,
) => useOperatorsCustom({ managers: true }, options);

export const useOperator = <Data = Operator>(
  id?: ObjectId,
  options?: Omit<
    UseQueryOptions<Operator, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<Operator, ApiError, Data>({
    queryKey: ['operators', id],
    queryFn: async () => {
      return api.get<Operator>(`/operators/${id}`).then(({ data }) => data);
    },
    enabled: !!id && options?.enabled !== false,
    ...options,
  });

export const useCurrent = <Data = Operator>(
  options?: Omit<
    UseQueryOptions<Operator, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<Operator, ApiError, Data>({
    queryKey: ['profile'],
    queryFn: async () => {
      if (!getToken()) {
        throw new Error('Token not found');
      }
      return api.get<Operator>('/operator/profile').then(({ data }) => data);
    },
    ...options,
  });
};
