import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Field as FormikField, useFormikContext } from 'formik';
import {
  Checkbox as AntdCheckbox,
  DatePicker as AntdDatePicker,
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  Select as AntdSelect,
  TimePicker as AntdTimePicker,
  Button,
  Popover,
} from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import { isValidNumber } from 'shared/helpers';

/**
 * @param {string} query
 * @return {string}
 */
export const cleanSearchQuery = (query) => {
  let value = query;

  if (['+', '7'].includes(query.charAt(0))) {
    value = value.replace(/\D+/g, '');
  }

  return value;
};

export const Input = ({ field, form, type, textarea, parser, ...props }) => {
  const onChange = (e) => {
    let value = e?.currentTarget?.value ?? e;
    if (type === 'text' && parser) value = parser(value);

    if (field.name === 'search') {
      value = cleanSearchQuery(value);
    }
    if (type === 'number') {
      if ((value || value === 0) && !Number.isNaN(Number(value))) {
        value = props.stringMode ? value : Number(value);
      } else {
        value = null;
      }
    }
    if (props.clearEmpty && value === '') {
      value = null;
    }
    form.setFieldValue(field.name, value);
  };

  const Component = useMemo(() => {
    if (textarea) return AntdInput.TextArea;
    switch (type) {
      case 'number':
        return AntdInputNumber;
      case 'password':
        return AntdInput.Password;
      default:
        return AntdInput;
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [type, textarea]);

  return (
    <Component
      style={{ width: '100%' }}
      defaultValue={field.value}
      value={field.value ?? null}
      onChange={onChange}
      {...props}
    />
  );
};

Input.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  textarea: PropTypes.bool,
  type: PropTypes.string,
  stringMode: PropTypes.bool,
  clearEmpty: PropTypes.bool,
  parser: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  textarea: false,
  stringMode: false,
  clearEmpty: false,
  parser: null,
};

export const RangeNumberInput = ({
  field,
  form,
  min: minAllow,
  max: maxAllow,
  delimiter,
  fromPlaceholder,
  toPlaceholder,
  /** сохранять значения в виде массива [от, до] */
  arrayMode,
  ...props
}) => {
  const [min, max] = useMemo(() => {
    const val = field.value;
    if (!val) return [];
    if (arrayMode) return val; // NOTE: в данном случае val у нас уже [min, max]
    return val.split(delimiter)?.map((el) => {
      const parsed = parseInt(el, 10);
      return Number.isNaN(parsed) ? undefined : parsed;
    });
  }, [arrayMode, delimiter, field.value]);

  const onChange = (vals) => {
    if (isValidNumber(vals[0]) || isValidNumber(vals[1])) {
      const val =
        arrayMode ?
          [vals[0], vals[1]]
        : `${vals[0] ?? ''}${delimiter}${vals[1] ?? ''}`;
      form.setFieldValue(field.name, val);
    } else {
      form.setFieldValue(field.name, undefined);
    }
  };

  return (
    <div className="flex" style={{ alignItems: 'center' }}>
      <AntdInputNumber
        onChange={(val) => onChange([val, max])}
        value={min}
        min={minAllow}
        max={Math.min(maxAllow, max ?? Infinity)}
        placeholder={fromPlaceholder}
        {...props}
      />
      <SwapRightOutlined />
      <AntdInputNumber
        onChange={(val) => onChange([min, val])}
        value={max}
        min={Math.max(minAllow, min ?? 0)}
        max={maxAllow}
        placeholder={toPlaceholder}
        {...props}
      />
    </div>
  );
};

RangeNumberInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  delimiter: PropTypes.string,
  fromPlaceholder: PropTypes.string,
  toPlaceholder: PropTypes.string,
  arrayMode: PropTypes.bool,
};

RangeNumberInput.defaultProps = {
  min: 14,
  max: Infinity,
  delimiter: '..',
  fromPlaceholder: 'от',
  toPlaceholder: 'до',
  arrayMode: false,
};

export const TimePicker = ({ field, form, popupStyle, format, ...props }) => {
  const [value, setValue] = useState(field.value);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const onBlur = useCallback(() => {
    const newValue = value ? moment(value, format).format(format) : undefined;
    form.setFieldValue(field.name, newValue);
  }, [field.name, form, format, value]);

  return (
    <AntdTimePicker
      format={format}
      value={value && moment(value, format)}
      popupStyle={popupStyle}
      popupClassName="ant-picker-no-footer"
      onSelect={setValue}
      onBlur={onBlur}
      min={0}
      step="any"
      {...props}
    />
  );
};

TimePicker.propTypes = {
  field: {}.isRequired, // Formik field
  form: {}.isRequired, // Formik form controller
  format: PropTypes.string,
  popupStyle: stylePropType,
};

TimePicker.defaultProps = {
  popupStyle: {},
  format: 'HH:mm',
};

export const Checkbox = ({
  field,
  form,
  label,
  style,
  labelStyle,
  reverse,
  clearFalse,
  ...props
}) => {
  const onChange = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      if (clearFalse && !isChecked) {
        form.setFieldValue(field.name, undefined);
        return;
      }
      form.setFieldValue(field.name, reverse !== isChecked);
    },
    [field.name, form, reverse, clearFalse],
  );

  const isChecked =
    field.value === true || `${field.value}`.toLowerCase() === 'true';

  return (
    <AntdCheckbox
      style={{ width: '100%', display: 'inline-flex', ...style }}
      onChange={onChange}
      checked={reverse !== isChecked}
      {...props}
    >
      <span style={labelStyle}>{label}</span>
    </AntdCheckbox>
  );
};

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  labelStyle: stylePropType,
  style: stylePropType,
  reverse: PropTypes.bool,
  clearFalse: PropTypes.bool,
};

Checkbox.defaultProps = {
  style: {},
  labelStyle: {},
  reverse: false,
  clearFalse: false,
};

export const CheckboxList = ({
  field,
  form,
  style,
  list: listProp,
  overwrite,
  required,
  ...props
}) => {
  const setValue = useCallback(
    (value) => {
      form.setFieldValue(field.name, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.name],
  );

  const list = useMemo(() => {
    if (Array.isArray(listProp)) {
      return listProp.reduce((acc, el) => {
        if (typeof el === 'string') {
          acc[el] = el;
        } else {
          acc[el.value] = el.caption;
        }
        return acc;
      }, {});
    }
    return listProp;
  }, [listProp]);

  const overwriteItems = useMemo(() => {
    if (!Object.keys(overwrite).length) {
      return [];
    }
    return Object.entries(overwrite)
      .filter((entry) => entry[1].checked)
      .map(([item]) => item);
  }, [overwrite]);

  useEffect(() => {
    const val = field.value;
    if (!val) {
      setValue([]);
      return;
    }
    if (!overwriteItems.every((over) => val.includes(over))) {
      const overwrittenValue = overwriteItems.reduce((acc, cur) => {
        return acc.includes(cur) ? acc : [...acc, cur];
      }, val);
      setValue(overwrittenValue);
    }
  }, [field.value, overwriteItems, setValue]);

  const onChange = (item) => (e) => {
    const val = field.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      if (!val.includes(item)) {
        setValue([...val, item]);
      }
    } else if (val.includes(item)) {
      setValue(val.filter((el) => el !== item));
    }
  };

  return (
    <>
      {Object.entries(list).map(([item, label]) => (
        <AntdCheckbox
          style={{ width: '100%', display: 'inline-flex', ...style }}
          onChange={onChange(item)}
          checked={field.value?.includes(item)}
          value={field.value?.includes(item)}
          required={required && !field.value?.length}
          key={item}
          {...props}
          {...overwrite[item]}
        >
          {label}
        </AntdCheckbox>
      ))}
    </>
  );
};

CheckboxList.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  list: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  overwrite: PropTypes.objectOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ),
  style: stylePropType,
  required: PropTypes.bool,
};

CheckboxList.defaultProps = {
  overwrite: {},
  style: {},
  required: false,
};

export const DatePicker = ({
  field,
  form,
  format = 'DD.MM.YYYY',
  dataFormat = 'YYYY-MM-DD',
  ...props
}) => {
  const onChange = (value) => {
    form.setFieldValue(field.name, value ? value.format(dataFormat) : null);
  };

  return (
    <AntdDatePicker
      style={{ width: '100%' }}
      onChange={onChange}
      format={props.showTime ? format : [format, format.replace(/\W/gi, '')]}
      formatter={(value) => {
        console.log(value);
        return value;
      }}
      defaultValue={field.value && moment(field.value, dataFormat)}
      value={field.value && moment(field.value, dataFormat)}
      {...props}
    />
  );
};

DatePicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  format: PropTypes.string,
  showTime: PropTypes.bool,
  dataFormat: PropTypes.string,
};

export const RangePicker = ({ field, form, ...props }) => {
  const onChange = (value) => {
    let newValue;
    if (value && value.length) {
      newValue = [value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD')];
    }
    form.setFieldValue(field.name, newValue);
  };

  const value = useMemo(
    () =>
      field.value?.[0] && field.value?.[1] ?
        [moment(field.value?.[0]), moment(field.value?.[1])]
      : [],
    [field.value],
  );

  const ranges = useMemo(() => {
    const now = moment();
    // noinspection NonAsciiCharacters
    return {
      Сегодня: [now, now],
      'Эта неделя': [now.clone().startOf('week'), now.clone().endOf('week')],
      'Этот месяц': [now.clone().startOf('month'), now.clone().endOf('month')],
    };
  }, []);

  return (
    <AntdDatePicker.RangePicker
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      placeholder=""
      format="DD.MM.YYYY"
      ranges={ranges}
      {...props}
    />
  );
};

RangePicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
};

export const RangePickerLimit = ({
  form,
  field,
  maxDays,
  defaultValue: initDefaultValue,
  ...props
}) => {
  const defaultValue =
    initDefaultValue ??
    (field.value?.[0] && field.value?.[1] ?
      [moment(field.value?.[0]), moment(field.value?.[1])]
    : []);

  const [dates, setDates] = useState([]);
  const [value, setValue] = useState(defaultValue);
  const [hackValue, setHackValue] = React.useState(undefined);

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDays - 1;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDays - 1;

    return tooEarly || tooLate;
  };

  const onChange = useCallback(
    (val) => {
      let newValue = null;
      if (val && val.length) {
        newValue = [val[0].format('YYYY-MM-DD'), val[1]?.format('YYYY-MM-DD')];
      }
      form.setFieldValue(field.name, newValue);
      setValue(val);
    },
    [field.name, form],
  );

  useEffect(() => {
    if (field.value?.[0] === value?.[0] && field.value?.[1] === value?.[1])
      return;
    setValue(
      field.value?.[0] && field.value?.[1] ?
        [moment(field.value?.[0]), moment(field.value?.[1])]
      : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onOpenChange = useCallback((open) => {
    if (open) {
      setDates([]);
      setHackValue([]);
    } else {
      setHackValue(undefined);
    }
  }, []);

  const ranges = useMemo(() => {
    const now = moment();
    // noinspection NonAsciiCharacters
    return {
      Сегодня: [now, now],
      'Эта неделя': [now.clone().startOf('week'), now.clone().endOf('week')],
      'Этот месяц': [now.clone().startOf('month'), now.clone().endOf('month')],
    };
  }, []);

  return (
    <AntdDatePicker.RangePicker
      disabledDate={disabledDate}
      value={hackValue || value}
      onOpenChange={onOpenChange}
      onCalendarChange={setDates}
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      onChange={onChange}
      placeholder=""
      format="DD.MM.YYYY"
      ranges={ranges}
      {...props}
    />
  );
};

RangePickerLimit.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  maxDays: PropTypes.number.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
};

RangePickerLimit.defaultProps = {
  defaultValue: null,
};

export const Select = ({
  field = {},
  form,
  list: listData,
  listWidth,
  onChange,
  disabledOptions,
  enabledOptions,
  ...props
}) => {
  const [list, setList] = useState([]);

  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
    if (onChange) {
      onChange(value, form, field);
    }
  };

  const optionDisabled = useCallback(
    (option) =>
      disabledOptions?.includes(option) ||
      (enabledOptions && !enabledOptions.includes(option)),
    [disabledOptions, enabledOptions],
  );

  useEffect(() => {
    if (Array.isArray(listData)) {
      const newList = listData.map((elem) => {
        if (typeof elem === 'string') {
          return (
            <AntdSelect.Option
              key={elem}
              value={elem}
              disabled={optionDisabled(elem)}
            >
              {elem}
            </AntdSelect.Option>
          );
        }
        return (
          <AntdSelect.Option
            key={elem.value}
            value={elem.value}
            disabled={optionDisabled(elem.value)}
          >
            {elem.caption}
          </AntdSelect.Option>
        );
      });

      setList(newList);
    } else {
      const newList = Object.keys(listData ?? {}).map((key) => {
        const label = listData[key];
        return (
          <AntdSelect.Option
            key={key}
            value={key}
            disabled={optionDisabled(key)}
          >
            {label}
          </AntdSelect.Option>
        );
      });

      setList(newList);
    }
  }, [field.name, listData, optionDisabled]);

  return (
    <AntdSelect
      style={{ width: '100%' }}
      defaultValue={field.value}
      value={field.value}
      showSearch
      onChange={handleChange}
      dropdownMatchSelectWidth={listWidth || true}
      filterOption={(input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      allowClear
      {...props}
    >
      {list}
    </AntdSelect>
  );
};

Select.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  list: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  listWidth: PropTypes.number,
  onChange: PropTypes.func,
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
  enabledOptions: PropTypes.arrayOf(PropTypes.string),
};

Select.defaultProps = {
  list: {},
  listWidth: null,
  onChange: null,
  disabledOptions: null,
  enabledOptions: null,
};

export const SelectWithGroups = ({ field, form, list, ...props }) => {
  const onChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  const groups = Object.keys(list ?? {});
  const selectGroups = groups.map((group) => (
    <AntdSelect.OptGroup key={group} label={group}>
      {list[group].map((city) => (
        <AntdSelect.Option key={city} value={city}>
          {city}
        </AntdSelect.Option>
      ))}
    </AntdSelect.OptGroup>
  ));
  return (
    <AntdSelect
      style={{ width: '100%' }}
      defaultValue={field.value}
      value={field.value}
      {...props}
      onChange={onChange}
    >
      {selectGroups}
    </AntdSelect>
  );
};

SelectWithGroups.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  list: PropTypes.objectOf(PropTypes.string).isRequired,
};

const Notice = ({ notice, indent }) => (
  <Popover content={notice} trigger="click" placement="top">
    <Button
      style={{
        marginLeft: indent,
        borderRadius: '100%',
        width: 24,
        height: 24,
        padding: 0,
        flexShrink: 0,
      }}
    >
      ?
    </Button>
  </Popover>
);

Notice.propTypes = {
  notice: PropTypes.string.isRequired,
  indent: PropTypes.string,
};

Notice.defaultProps = {
  indent: '0',
};

const ErrorMessage = ({ error }) => {
  const style = {
    color: 'red',
    fontSize: 10,
    paddingTop: 5,
  };

  if (!error) return null;

  return <div style={style}>{error}</div>;
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: null,
};

// NOTE: нужно для того, чтобы тайпскрипт принимал пропы
/** @param {{ [prop: string]: any }} props */
export const FormGroup = ({
  label,
  notice,
  className,
  wrapperStyle,
  component,
  required,
  markRequired,
  name,
  enabledFields,
  disabledFields,
  disabled,
  ...props
}) => {
  const { errors } = useFormikContext();
  const error = errors[name];

  return (
    <div className={`form-group ${className}`} style={wrapperStyle}>
      <div className="flex">
        <label htmlFor={name}>{label}</label>
        {(required || markRequired) && (
          <span style={{ color: 'red', marginLeft: '.5ch' }}>*</span>
        )}
        {notice && <Notice notice={notice} indent="1ch" />}
      </div>
      <FormikField
        id={name}
        component={component}
        label={label}
        required={required}
        name={name}
        allowClear
        disabled={
          disabled ||
          disabledFields.includes(name) ||
          (enabledFields && !enabledFields.includes(name))
        }
        {...props}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

FormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  notice: PropTypes.string,
  className: PropTypes.string,
  wrapperStyle: stylePropType,
  component: PropTypes.elementType,
  required: PropTypes.bool,
  markRequired: PropTypes.bool,
  enabledFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

FormGroup.defaultProps = {
  notice: undefined,
  className: '',
  wrapperStyle: { width: '100%' },
  component: Input,
  required: false,
  markRequired: false,
  enabledFields: null,
  disabledFields: [],
  disabled: false,
};

// NOTE: нужно для того, чтобы тайпскрипт принимал пропы
/** @param {{ [prop: string]: any }} props */
export const Field = ({
  label,
  notice,
  component,
  name,
  enabledFields,
  disabledFields,
  disabled,
  wrapperStyle,
  ...props
}) => {
  return (
    <span className="flex flex-center mr4" style={wrapperStyle}>
      <FormikField
        id={name}
        name={name}
        component={component}
        label={label}
        allowClear
        disabled={
          disabled ||
          disabledFields.includes(name) ||
          (enabledFields && !enabledFields.includes(name))
        }
        {...props}
      />
      {notice && <Notice notice={notice} />}
    </span>
  );
};

Field.propTypes = {
  label: PropTypes.string.isRequired,
  notice: PropTypes.string,
  component: PropTypes.elementType,
  name: PropTypes.string.isRequired,
  enabledFields: PropTypes.arrayOf(PropTypes.string),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  wrapperStyle: stylePropType,
};

Field.defaultProps = {
  notice: undefined,
  component: Input,
  enabledFields: null,
  disabledFields: [],
  disabled: false,
};
